// Generated by Framer (91d32d4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["rKZRCnTMd", "zCdbjijjU"];

const serializationHash = "framer-gBpaa"

const variantClassNames = {rKZRCnTMd: "framer-v-i70ry", zCdbjijjU: "framer-v-1s7jdmq"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {delay: 0, duration: 0.2, ease: [0.44, 0, 0.56, 1], type: "tween"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Grey Circle": "rKZRCnTMd", "Orange Circle": "zCdbjijjU"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "rKZRCnTMd"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "rKZRCnTMd", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-i70ry", className, classNames)} data-framer-name={"Grey Circle"} initial={variant} layoutDependency={layoutDependency} layoutId={"rKZRCnTMd"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-74805edf-df95-462b-b18a-c46302cbfef2, rgb(98, 100, 108))", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} variants={{zCdbjijjU: {backgroundColor: "var(--token-006fdba6-9690-47f6-9eee-17e66a9c00aa, rgb(216, 80, 64))"}}} {...addPropertyOverrides({zCdbjijjU: {"data-framer-name": "Orange Circle"}}, baseVariant, gestureVariant)}/></Transition>
</LayoutGroup>)

});

const css = [".framer-gBpaa[data-border=\"true\"]::after, .framer-gBpaa [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gBpaa.framer-l91v9u, .framer-gBpaa .framer-l91v9u { display: block; }", ".framer-gBpaa.framer-i70ry { height: 14px; position: relative; width: 14px; }", ".framer-gBpaa.framer-v-1s7jdmq.framer-i70ry { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 14px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 14
 * @framerIntrinsicWidth 14
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"zCdbjijjU":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framery8iasaI8R: React.ComponentType<Props> = withCSS(Component, css, "framer-gBpaa") as typeof Component;
export default Framery8iasaI8R;

Framery8iasaI8R.displayName = "Timeline/Timeline Circle";

Framery8iasaI8R.defaultProps = {height: 14, width: 14};

addPropertyControls(Framery8iasaI8R, {variant: {options: ["rKZRCnTMd", "zCdbjijjU"], optionTitles: ["Grey Circle", "Orange Circle"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framery8iasaI8R, [])